import server from '@/api';

//登录
export function deviceLogin(param) {
  return server.login.deviceLogin(param);
}

//获取图形验证码
export function getWXImgCode(param) {
  return server.login.getImgCode(param);
}

// 获取用户权限
export function getPermission() {
  // server.permission.getPermission().then(res => {
  //     if (res.errno === 0) {
  //         if (res.data) {
  //             let userPermission = JSON.stringify(res.data);
  //             localStorage.setItem('userPermission', userPermission);
  //         } else {
  //             console.log(res.data);
  //         }
  //     }
  // });
}

//查询账号类型
export function selectAccountTypeByLoginName(param) {
  return server.users.selectAccountTypeByLoginName(param);
}

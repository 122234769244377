<template>
  <div class="wrap">
    <!-- <div class="header">
      <img @click="linkTo('/home/HomePage')" src="@/assets/login-logo.png" alt="" />
      <span style="color:#DDDDDD">|</span>
      <span class="logo-name">服务商中心</span>
      <router-link to="/home/HomePage" class="header-right">返回商城 <i class="el-icon-arrow-right"></i> </router-link>
    </div> -->
    <div class="mainCompatible" v-if="!compatible">
      <p class="font-mainCompatible">请使用谷歌、火狐、dge或是360浏览器极速模式访问</p>
    </div>
    <div class="main" v-else>
      <div class="main-left">
        <div class="main-bg" :style="{'min-height': clientHeight}">
          <img src="@/assets/map/login-bg.jpg" alt="" />
        </div>
        <div class="login" key="login">
          <span class="login-title">欢迎登录</span>
          <div class="login-main">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
              <el-form-item prop="nickname">
                <el-input placeholder="请输入登录名" v-model="ruleForm.nickname" @keyup.enter.native="login('ruleForm')">
                  <img slot="prefix" style="padding: 0 10px; padding-top: 14px" src="@/assets/name.png" alt="" />
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入密码" v-model="ruleForm.password" show-password @keyup.enter.native="login('ruleForm')">
                  <img slot="prefix" style="padding: 0 10px; padding-top: 14px" src="@/assets/logo-password.png" alt="" />
                </el-input>
              </el-form-item>
              <el-form-item prop="picCode">
                <div class="form-item image">
                  <el-input placeholder="图形验证码" v-model="ruleForm.picCode" style="margin-right: 20px" @keyup.enter.native="login('ruleForm')"></el-input>
                  <div class="figure_code" @click="getImgCode()">
                    <img v-bind:src="'data:image/png;base64,' + imagebase" />
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="login-button" @click="login('ruleForm')">登 录 </el-button>
              </el-form-item>

              <!-- <div class="login-foot">
                                <router-link to="/home/UserRegister">
                                    <span class="foot-item">立即注册</span>
                                </router-link>
                                <span style="margin:0 10px">|</span>
                                <router-link to="/home/Login?state=forgetPassword">
                                    <span class="foot-item">忘记密码</span>
                                </router-link>
                            </div> -->
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import {deviceLogin, getWXImgCode, getPermission, selectAccountTypeByLoginName} from './api';

import Foot from '@/components/Foot.vue';
export default {
  created() {
    this.clientHeight = document.body.clientHeight - 300 + 'px';
    // 检验是否为IE
    this.BrowserType();
  },
  mounted() {
    //获取图形验证码
    this.getImgCode();
  },

  components: {
    Foot
  },
  data() {
    return {
      compatible: true,
      clientHeight: '',
      resubmit: true,
      rules: {
        nickname: [
          {
            required: true,
            message: '请输入登录名',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        picCode: [
          {
            required: true,
            message: '请输入图形验证码',
            trigger: 'blur'
          }
        ]
      },
      ruleForm: {
        nickname: '',
        password: '',
        picCode: ''
      },
      imagebase: '',
      imgUuid: ''
    };
  },
  methods: {
    linkTo(path) {
      this.$router.push({path});
    },
    getImgCode() {
      var _this = this;
      getWXImgCode().then(res => {
        _this.imagebase = res.data.img;
        if (_this.imgUuid == res.data.uuid) {
          _this.offcache();
        }
        _this.imgUuid = res.data.uuid;
      });
    },

    offcache() {
      this.$notify.warning({
        title: '提示',
        message: '请前往浏览器internet选项>设置>internet临时文件：勾选:每次访问网页时（E）'
      });
    },
    // 登录
    login(formName) {
      var _this = this;
      // var isLogin = false;

      _this.$refs[formName].validate(valid => {
        if (valid) {
          if (!_this.resubmit) return false;

          _this.resubmit = false;
          // console.log(_this.ruleForm.nickname)
          // console.log(_this.ruleForm.password)

          deviceLogin({
            username: _this.ruleForm.nickname,
            password: _this.ruleForm.password,
            code: _this.ruleForm.picCode, 
            uuid: _this.imgUuid
          })
            .then(res => {
              if (res.errno == 0) {
                let {errmsg} = res;
                //   _this.$message.success('登录成功');
                _this.setInof('logined', _this.ruleForm.nickname);
                _this.setInof('loginSession', res.data);
                getPermission();

                localStorage.setItem('loginName', _this.ruleForm.nickname);
                // 开发使用，打包记得注销
                localStorage.setItem('Token',  res.data);
                _this.ruleForm = {
                  nickname: '',
                  password: ''
                };
                // isLogin = true;

                var loginName = localStorage.getItem('loginName');

                this.$notify.success({
                  title: '成功',
                  message: errmsg
                });

                _this.$router.push({
                  path: '/DeviceMap?t=' + Date.now()
                });

                // selectAccountTypeByLoginName({
                //   loginName: loginName
                // }).then(res => {
                //   if (res.errno == 0) {
                //     console.log(res);
                //     localStorage.setItem('accountType', res.data.accountType);
                //     localStorage.setItem('userId', res.data.id);
                //     localStorage.setItem('parentId', res.data.parentId);
                //     localStorage.setItem('tel', res.data.tel);
                //     localStorage.setItem('enterpriseId', res.data.enterpriseId);
                //     localStorage.setItem('users', res.data.users);
                //     localStorage.setItem('avatar', res.data.avatar);
                //     localStorage.setItem('userInfo', JSON.stringify(res.data || {}));

                //     _this.$router.push({
                //       path: '/DeviceMap?t=' + Date.now()
                //     });
                //   }
                //   _this.resubmit = true;
                // });
              } else {
                getImgCode(_this);
                _this.resubmit = true;
              }
            })
            .catch(res => {
              _this.resubmit = true;
            });
        }
      });
    },

    setInof(key, value) {
      localStorage.setItem(key, value);
      this.setCookie(key, value); //存储localStorage的同时，也存储一个cookie来监听
    },

    setCookie(name, value, seconds) {
      seconds = seconds || 0; //seconds有值就直接赋值，没有为0
      var expires = '';
      if (seconds != 0) {
        //设置cookie生存时间
        var date = new Date();
        date.setTime(date.getTime() + seconds * 1000);
        expires = '; expires=' + date.toGMTString();
      }
      document.cookie = name + '=' + escape(value) + expires + '; path=/'; //转码并赋值
    },


    BrowserType() {
      var userAgent = window.navigator.userAgent;

      if (userAgent.indexOf('Trident') > -1) {
        //  this.$message({message: '请使用谷歌、火狐、edge或是360浏览器极速模式', duration: 0, showClose: true,   type: 'warning'});
        this.compatible = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@hoverBlue: #188aff;

.wrap {
  background: #fff;
  display: flex;
  flex-direction: column;
  // height: 100%;
  height: 100vh;

  .header {
    height: 40px;
    padding: 20px 60px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(24, 138, 255, 0.2);
    z-index: 10;

    .logo-name {
      color: #333;
      font-size: 20px;
    }

    span {
      margin-left: 20px;
      font-size: 18px;
    }

    img {
      cursor: pointer;
      height: 100%;
    }

    .header-right {
      // height: 80px;
      color: #a7b1c1;
      margin-left: auto;
      font-size: 15px;

      &:hover {
        color: @hoverBlue;
      }
    }
  }

  .mainCompatible {
    width: 100%;
    height: 1000px;

    .font-mainCompatible {
      margin-top: 40px;
      text-align: center;
      font-size: 30px;
    }
  }

  .main {
    flex: 1;
    background: #fff;
    .main-left {
      width: 60%;
      height: 100%;
      position: relative;

      .main-bg {
        flex: 1;
        height: 100%;
        min-height: 500px;

        img {
          // height: 100%;
          // width: 100%;
          object-fit: fill;
        }
      }

      .login {
        position: absolute;
        top: 50%;
        right: -486px;
        margin-top: -237px;
        box-shadow: 0px 4px 30px rgba(24, 138, 255, 0.3);
        width: 426px;
        height: 475px;
        background: #fff;
        border-radius: 10px;
        padding: 43px 63px 63px;
        box-sizing: border-box;
        overflow: hidden;

        .login-title {
          position: relative;
          font-size: 22px;
          font-weight: bold;

          &::after {
            position: absolute;
            left: 0px;
            top: 18px;
            width: 100%;
            height: 10px;
            content: '';
            background: linear-gradient(to right, rgba(0, 169, 255, 0.3), rgba(10, 125, 239, 0.3));
          }
        }

        .login-main {
          padding: 30px 0 20px;

          .form-item {
            display: flex;
          }

          .login-button {
            border: 0;
            font-size: 16px;
            width: 100%;
            box-shadow: 0px 4px 10px rgba(24, 138, 255, 0.3);
            background-image: linear-gradient(to right, #00a9ff, #0a7def);
            margin-top: 10px;
            padding: 16px 0;
          }
        }

        .login-foot {
          .foot-item {
            color: #a7b1c1;
            font-size: 14px;

            &:hover {
              color: @hoverBlue;
            }
          }
        }
      }
    }

    .main-right {
      min-width: 10px;
      flex: 1;
      height: 100%;
    }
  }
}

/deep/.el-input__inner {
  background: #f5f5fd;
  color: #333;
  border: none;
  padding: 0 46px;
}

/deep/.image .el-input__inner {
  padding: 0 20px;
}

/deep/.el-form-item {
  padding-bottom: 10px;
}
</style>
